<template>
  <div>
    <!-- Filters -->
    <app-collapse hover>
      <app-collapse-item title="Filters">
        <update-profile-request-list-filters
          :status-filter.sync="statusFilter"
          :status-options="updateProfileRequestStatusOption"
          :requested-by-filter.sync="requestedByFilter"
          :employee-options="interviewersData"
        />
      </app-collapse-item>
    </app-collapse>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-checkbox
              v-if="
                updateProfileRequestsMetaData.length &&
                $can('update_profile_request_delete')
              "
              class="mr-2"
              v-model="isDeleteMultipleCandChecked"
            >
            </b-form-checkbox>
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div
              v-if="selectedUpdateProfileRequests.length == 0"
              class="d-flex align-items-center justify-content-end"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search Request By"
                />
              </b-input-group>
              <!-- <b-button
                v-if="$can('update_profile_request_create')"
                variant="primary"
                :to="{ name: 'add-update-profile-request' }"
              >
                <span class="text-nowrap">Add Update Profile Request</span>
              </b-button> -->
            </div>
            <b-button
              v-else-if="$can('update_profile_request_delete')"
              class="float-right"
              variant="danger"
              :disabled="updateProfileRequestsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ updateProfileRequestsDeleteCount }} Update Profile
                Request</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selectedUpdateProfileRequests"
        name="selectedUpdateProfileRequests"
      >
        <b-table
          ref="refUserListTable"
          class="position-relative"
          :items="fetchUpdateProfileRequestList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #cell(select)="data">
            <div class="text-nowrap">
              <b-form-checkbox :value="data.item.hashid"> </b-form-checkbox>
            </div>
          </template>
          <!-- Column: User -->
          <template #cell(request_by_name)="data">
            <b-media vertical-align="center">
              <b-link
                v-if="$can('update_profile_request_show')"
                :to="{
                  name: 'view-update-profile-request',
                  params: { id: data.item.hashid },
                }"
                class="font-weight-bold d-inline-block text-nowrap"
              >
                {{ data.item.request_by_name }}
              </b-link>
              <span v-else>
                {{ data.item.request_by_name }}
              </span>
            </b-media>
          </template>

          <!--Column: Status -->
          <template #cell(status_label)="data">
            <b-badge
              pill
              :variant="`${resolveUserStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ data.item.status_label }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <feather-icon
              @click="editRecord(data)"
              v-if="$can('update_profile_request_edit')"
              v-b-tooltip.hover
              title="Edit"
              icon="EditIcon"
              size="16"
              class="mr-1 cursor-pointer"
            />
            <feather-icon
              @click="viewRecord(data)"
              v-if="$can('update_profile_request_show')"
              v-b-tooltip.hover
              title="View"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer"
            />
            <b-dropdown
              variant="link"
              size="sm"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="17"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                class="p-0 btn-sm"
                @click="notifyUser(data.item.hashid)"
                v-if="$can('update_profile_request_edit')"
              >
                <feather-icon icon="MailIcon" class="mr-50" />
                <span>Notify User</span>
              </b-dropdown-item>

              <b-dropdown-item
                class="p-0 btn-sm"
                @click="confirmDeleteRecord(data)"
                v-if="$can('update_profile_request_delete')"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-form-checkbox-group>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="selectedUpdateProfileRequests.length == 0"
              v-model="currentPage"
              :total-rows="totalUpdateProfileRequestList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <b-button
              v-else-if="$can('update_profile_request_delete')"
              class="float-right"
              variant="danger"
              :disabled="updateProfileRequestsDeleteCount == 0"
              @click="confirmDeleteMultipleRecords"
            >
              <span class="text-nowrap"
                >Delete {{ updateProfileRequestsDeleteCount }} Update Profile
                Request</span
              >
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import updateProfileRequestStoreModule from "../updateProfileRequestStoreModule";
import useUpdateProfileRequestList from "./useUpdateProfileRequestList";
import UpdateProfileRequestListFilters from "./UpdateProfileRequestListFilters.vue";

export default {
  components: {
    UpdateProfileRequestListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-update-profile-requests";
    const isDeleteMultipleCandChecked = ref(false);
    const selectedUpdateProfileRequests = ref([]);
    const updateProfileRequestsDeleteCount = ref(0);

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        updateProfileRequestStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchUpdateProfileRequestList,
      tableColumns,
      perPage,
      currentPage,
      totalUpdateProfileRequestList,
      updateProfileRequestsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,
      filterByUpdateProfileRequestUrgencyStatus,
      filterByUpdateProfileRequestType,
      resolveUserStatusVariant,
      updateProfileRequestStatusOption,
      requestedByFilter,
    } = useUpdateProfileRequestList();

    watch(isDeleteMultipleCandChecked, (val) => {
      selectedUpdateProfileRequests.value = [];
      if (val) {
        updateProfileRequestsMetaData.value.forEach(
          (update_profile_request) => {
            selectedUpdateProfileRequests.value.push(
              update_profile_request.hashid
            );
          }
        );
      }
    });

    watch(selectedUpdateProfileRequests, (val) => {
      updateProfileRequestsDeleteCount.value = val.length;
    });

    return {
      // Sidebar
      fetchUpdateProfileRequestList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalUpdateProfileRequestList,
      // Extra Filters
      statusOptions,
      statusFilter,
      filterByUpdateProfileRequestUrgencyStatus,
      filterByUpdateProfileRequestType,
      selectedUpdateProfileRequests,
      updateProfileRequestsDeleteCount,
      isDeleteMultipleCandChecked,
      resolveUserStatusVariant,
      updateProfileRequestStatusOption,
      requestedByFilter,
      updateProfileRequestsMetaData,
    };
  },
  data() {
    return {
      interviewersData: [],
    };
  },
  created() {
    if (!this.$can("update_profile_request_delete")) {
      this.tableColumns = this.tableColumns.filter(
        (item) => item.key != "select"
      );
    }
    this.fetchInterviewersList();
  },
  methods: {
    editRecord(data) {
      this.$router
        .push(`/edit/update-profile-request/${data.item.hashid}`)
        .catch(() => {});
    },
    viewRecord(data) {
      this.$router
        .push(`/view/update-profile-request/${data.item.hashid}`)
        .catch(() => {});
    },
    confirmDeleteRecord(data) {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${data.item.request_by_name}.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRecord(data.item.hashid);
        }
      });
    },
    confirmDeleteMultipleRecords() {
      this.$swal({
        title: "Are you sure?",
        text: `You want to delete ${this.selectedUpdateProfileRequests.length} Update Profile Request, This cannot be undone.`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(
              "app-update-profile-requests/removeMultipleUpdateProfileRequests",
              this.selectedUpdateProfileRequests
            )
            .then((res) => {
              this.isDeleteMultipleCandChecked = false;
              this.selectedUpdateProfileRequests = [];
              if (res.data.success) {
                this.refetchData();
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted Successfully.",
                    icon: "BellIcon",
                    variant: "success",
                    text: res.data.message,
                  },
                });
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Update Profile Request Deletion failed.",
                    icon: "BellIcon",
                    variant: "danger",
                    text: res.data.message,
                  },
                });
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
    refetchUpdateProfileRequestsData() {
      console.log("Added Update Profile Request");
    },
    deleteRecord(id) {
      const self = this;
      this.$store
        .dispatch("app-update-profile-requests/removeUpdateProfileRequest", id)
        .then((res) => {
          if (res.status == "204") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Update Profile Request Deleted",
                icon: "BellIcon",
                variant: "success",
                text: "Update Profile Request Deleted Successfully.",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "Update Profile Request Deletion failed.",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
          self.refetchData();
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "Update Profile Request Deletion failed.",
              icon: "BellIcon",
              variant: "danger",
            },
          });
          console.error(err);
        });
    },
    notifyUser(id) {
      const self = this;
      this.$store
        .dispatch("app-update-profile-requests/notifyUser", id)
        .then((res) => {
          if (res.status == "200") {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "User notification",
                icon: "BellIcon",
                variant: "success",
                text: "The user will be notified soon.",
              },
            });
          } else {
            self.$toast({
              component: ToastificationContent,
              props: {
                title: "User notification",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((err) => {
          self.$toast({
            component: ToastificationContent,
            props: {
              title: "User notification",
              icon: "BellIcon",
              variant: "danger",
              text: "User notification failed.",
            },
          });
          console.error(err);
        });
    },
    openWindow(link) {
      window.open(link);
    },
    fetchInterviewersList() {
      this.$store
        .dispatch("app/fetchInterviewers")
        .then((res) => {
          this.interviewersData = res.data.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
